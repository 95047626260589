import React from 'react'

import { GiPartyHat, GiPartyPopper } from 'react-icons/gi';
import { IoGlassesOutline } from 'react-icons/io5';
import { CgYinyang } from 'react-icons/cg';
import { FaHatWizard } from 'react-icons/fa';
import Team from '../Team';

const features = [
    { name: 'Eksperter på bortgjemte data', icon: IoGlassesOutline, description: <>Vi forstår hvordan it-systemer er sammensatt, og kan hjelpe dere med å finne gode løsninger som forbedrer kvalitet og hverdag for&nbsp;brukerene.</> },
    {
        name: 'Opptatt av god brukeropplevelse', icon: CgYinyang, description: <>Vi brenner for å lage løsninger som gjør de intuitive og enkle i bruk, og investerer mye tid på å finne gode løsninger for de som skal bruke&nbsp;tjenestene.</>
    },
    { name: 'Kreative problemløsere', icon: FaHatWizard, description: <>Vi er opptatt av at ting gjøres riktig, men tenker gjerne utenfor boksen for å finne den mest optimale løsningen på&nbsp;oppgaven.</>  },
    { name: 'Joviale folk', icon: GiPartyPopper, description: <>Vi mener selv vi er joviale og omgjengelige, og gjør godt inntrykk hos kundene våre. Vi er alltid åpne for en prat, så ikke nøl med å ta&nbsp;kontakt. </> },
];

export default function ConsultantFeature() {
    return (
        <div className="relative bg-white lg:pb-2">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                    {features.map((feature) => (
                        <div key={feature.name} className="pt-6">
                            <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                <div className="-mt-6">
                                    <div>
                                        <span className="inline-flex items-center justify-center p-3 bg-white rounded-md shadow-lg border-2">
                                            <feature.icon className="h-6 w-6 text-convivial" aria-hidden="true" />
                                        </span>
                                    </div>
                                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                                    <p className="mt-5 text-base text-gray-500">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Team />
        </div>
    );
}
