import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { GrUserManager } from 'react-icons/gr';

interface Project {
    bgOpacity: string;
    bgColor: string;
    background: JSX.Element;
    detail: JSX.Element;
    customerLogo: string;
    customerName: string;
    description: string;
    tags: string[];
}

// @ts-ignore
import SmartlivDetail from '../../../static/Projects/Overblikk.png';

// @ts-ignore
import MobileDetail from '../../../static/Projects/Mobile.png';

// @ts-ignore
import SmartlivLogo from '../../../static/Logos/smartliv.svg';

// @ts-ignore
import MobileLogo from '../../../static/Logos/mobile.svg';

// @ts-ignore
import FossLogo from '../../../static/Logos/foss-white.svg';

import CTA from "../CTA";

function SmartlivImage() {
    return <StaticImage className="w-full h-full object-cover" src="../../../static/Projects/smartliv3.jpg" alt="Smartliv" />;
}

function SmartlivDetailImage() {
    return <StaticImage className="object-contain" height={330} src="../../../static/Projects/Overblikk.png" alt="Smartliv" />;
    //return <img className="object-contain h-80" src={SmartlivDetail}  />
}

function MobileImage() {
    return <StaticImage className="w-full h-full object-cover" src="../../../static/Projects/mobile.jpg" alt="Mobile" />;
}

function MobileDetailImage() {
    return <StaticImage className="object-contain h-80" src="../../../static/Projects/Mobile.png" alt="Mobile" />;
    //return <img className="object-contain h-80" src={MobileDetail}  />
}

function FossImage() {
    return <StaticImage className="w-full h-full object-cover" src="../../../static/Projects/foss.jpg" alt="Foss" />;
}

function Quote() {
    return (
        <section className="bg-white bg-opacity-95 rounded-2xl shadow-2xl mb-12">
            <div className="max-w-6xl">
                <div className="">
                    <blockquote className="md:flex-grow md:flex md:flex-col">
                        <div className="relative text-lg font-medium md:flex-grow">
                            <svg
                                className="absolute top-5 left-5 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 32 32"
                            >
                                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                            </svg>
                            <p className="relative text-gray-700 px-6  py-5">
                                Da vi i Foss AS Fiberoptisk Systemsalg hadde behov for å fylle rollen som IT-ansvarlig, valgte vi å gå for innleie hos Convivial.
                                Gjennom denne ordningen har vi fått fleksibilitet, høy og bred kompetanse og ikke minst en strålende service.
                                Vi anbefaler Convivial på det varmeste!
                            </p>
                        </div>
                        <footer className="bg-gray-300 rounded-b-2xl px-6 py-4">
                            <div className="flex items-start">
                                <div className="">
                                    <div className="text-base font-medium text-gray-500">Kim Lieblein</div>
                                    <div className="text-base font-medium text-gray-500">CFO/COO, Foss AS Fiberoptisk Systemsalg</div>
                                </div>
                            </div>
                        </footer>
                    </blockquote>
                </div>
            </div>
        </section>
    );
}

export default function ProjectFeature() {

    const projects: Project[] = [
        {
            bgColor: "bg-black",
            bgOpacity: "bg-opacity-30",
            background: <SmartlivImage />,
            detail: <SmartlivDetailImage />,
            customerLogo: SmartlivLogo,
            customerName: "Smartliv AS",
            description: "Smart innsamling av data. Ved hjelp av moderne teknologi samler Smartliv inn vanndata for innbyggere i kommuner som benytter deres tjenester.",
            tags: [
                "UX-design",
                "Utvikling",
                "UI-design",
                "Prosjektledelse",
                "Arkitektur",
                "Rådgiving"
            ]
        },
        {
            bgColor: "bg-black",
            bgOpacity: "bg-opacity-50",
            background: <MobileImage />,
            detail: <MobileDetailImage />,
            customerLogo: MobileLogo,
            customerName: "Mobile AS",
            description: "Løsninger for mer effektiv hverdag. Moderne grensesnitt for innsyn i datavarehus, verksted og dialog med kundene.",
            tags: [
                "UX-design",
                "Utvikling",
                "UI-design",
                "Prosjektledelse",
                "Arkitektur",
            ]
        },
        {
            bgColor: "bg-black",
            bgOpacity: "bg-opacity-50",
            background: <FossImage />,
            detail: <Quote />,
            customerLogo: FossLogo,
            customerName: "Foss AS Fiberoptisk Systemsalg",
            description: "Foss fiberoptikk er en av Norges fremste leverandør av fiberprodukter for telekom, industri og forsvaret.",
            tags: [
                "Arkitektur",
                "IT-ansvarlig",
            ]
        }
    ];

    return (
        <>
            {projects.map((project, i) => {
                return <ProjectFeatureItem key={i} project={project} />;
            })}
        </>
    );
}

interface IProjectFeatureItem {
    project: Project;
}

/*
                        <img
                            src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                                                <StaticImage
                            className="w-full h-full object-cover"
                            alt=""
                            src={props.project.background}
                        />
*/
export function ProjectFeatureItem(props: IProjectFeatureItem) {
    return (
        <div className="bg-gray-100 pb-16 md:pb-16">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className={"relative px-8 rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8 " + props.project.bgColor}>
                    <div className="absolute inset-0">
                        {props.project.background}
                        <div className={"absolute inset-0 mix-blend-multiply " + props.project.bgColor + " " + props.project.bgOpacity} />
                    </div>
                    <div className="relative lg:col-span-1 pt-24 pb-24">
                        <img className="h-10 w-auto filter drop-shadow-2xl" src={props.project.customerLogo} alt={props.project.customerName} />
                        <blockquote className="mt-6 text-white">
                            <p className="text-xl font-medium sm:text-2xl">
                                {props.project.description}
                            </p>
                            <footer className="mt-6">
                                {props.project.tags.map((tag, i) => {
                                    return (
                                        <span key={i} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-1">
                                            {tag}
                                        </span>)
                                })}
                            </footer>
                        </blockquote>
                    </div>
                    <div className="hidden relative lg:col-span-1 lg:pt-16 lg:block lg:pl-12">
                        {props.project.detail}
                    </div>
                </div>
            </div>
        </div>
    );
}
